export const dashboardPath = [
  '/dashboard',
  '/online/dashboard',
  '/kids/dashboard',
  '/cafe/dashboard',
  '/company/dashboard',
];
export const orderBasketPath = ['/order-basket', '/online/order-basket', '/kids/order-basket', '/cafe/order-basket'];
export const orderPaymentDetailPath = [
  '/order/paymentdetail',
  '/online/order/paymentdetail',
  '/kids/order/paymentdetail',
  '/cafe/order/paymentdetail',
];
export const schedulePath = ['/schedule', '/online/schedule', '/kids/schedule', '/cafe/schedule', '/company/schedule'];
export const sessionsPath = ['/sessions', '/online/sessions', '/kids/sessions', '/cafe/sessions', '/company/sessions'];
export const ordersPath = ['/orders', '/online/orders', '/kids/orders', '/cafe/orders', '/company/orders'];
export const orderPackagePath = [
  '/order/package',
  '/online/order/package',
  '/kids/order/package',
  '/cafe/order/package',
];
export const orderTriallessonPath = [
  '/order/triallesson',
  '/online/order/triallesson',
  '/kids/order/triallesson',
  '/cafe/order/triallesson',
];
export const groupPath = [
  '/order/triallesson/group',
  '/online/order/triallesson/group',
  '/cafe/order/triallesson/group',
  '/kids/order/triallesson/group',
  '/order/package/group',
  '/online/order/package/group',
  '/cafe/order/package/group',
  '/kids/order/package/group',
];
export const privatePath = [
  '/order/triallesson/one-to-one',
  '/online/order/triallesson/one-to-one',
  '/kids/order/triallesson/one-to-one',
  '/order/package/one-to-one',
  '/online/order/package/one-to-one',
  '/kids/order/package/one-to-one',
];
export const speakersPath = ['/online/speakers', '/kids/speakers', '/cafe/speakers', '/company/speakers'];
export const speakerDetailsPath = [
  '/speakers/speaker-detail/:id',
  '/online/speakers/speaker-detail/:id',
  '/kids/speakers/speaker-detail/:id',
  '/cafe/speakers/speaker-detail/:id',
  '/company/speakers/speaker-detail/:id',
];
export const tiktokPaths = ['/tiktok', '/online/tiktok', '/kids/tiktok', '/cafe/tiktok', '/company/tiktok'];
export const landingPaths = [
  '/landing-online',
  '/landing-kadikoy',
  '/landing-mecidiyekoy',
  '/landing-besiktas',
  '/landing-camlica',
  '/landing-antalya',
  '/landing-beylikduzu',
];
export const metaVideoaskPaths = [
  { path: '/online-meta-videoask', videoId: 'fdmnp0s5w' },
  { path: '/istanbul-meta-videoask', videoId: 'ftdv5gcrz' },
  { path: '/beylikduzu-meta-videoask', videoId: 'ftdv5gcrz' },
  { path: '/mecidiyekoy-meta-videoask', videoId: 'fznxbu10v' },
  { path: '/antalya-meta-videoask', videoId: 'fwpkggdik' },
  { path: '/camlica-meta-videoask', videoId: 'f3wcgij0c' },
  { path: '/besiktas-meta-videoask', videoId: 'f4jkpl5sq' },
  { path: '/kadikoy-meta-videoask', videoId: 'f159vbwtv' },
];
export const googleVideoaskPaths = [
  { path: '/online-google-videoask', videoId: 'fesk5qgkl' },
  { path: '/istanbul-google-videoask', videoId: 'frmx88skx' },
  { path: '/beylikduzu-google-videoask', videoId: 'frmx88skx' },
  { path: '/mecidiyekoy-google-videoask', videoId: 'fg70bgoxn' },
  { path: '/antalya-google-videoask', videoId: 'fla8htmla' },
  { path: '/camlica-google-videoask', videoId: 'f0nt452mo' },
  { path: '/besiktas-google-videoask', videoId: 'f89homv9q' },
  { path: '/kadikoy-google-videoask', videoId: 'fj5u6fnr5' },
];
export const codeMetaVideoaskPaths = [
  { path: '/share50-online-meta-videoask', videoId: 'fwx14hkdr' },
  { path: '/istanbul-meta-videoask', videoId: 'ftdv5gcrz' },
  { path: '/share50-beylikduzu-meta-videoask', videoId: 'ftdv5gcrz' },
  { path: '/share50-mecidiyekoy-meta-videoask', videoId: 'fwonhm3db' },
  { path: '/share50-antalya-meta-videoask', videoId: 'f14vnbjmv' },
  { path: '/share50-camlica-meta-videoask', videoId: 'f71nkg30c' },
  { path: '/share50-besiktas-meta-videoask', videoId: 'f0yjskgyx' },
  { path: '/share50-kadikoy-meta-videoask', videoId: 'f74p1rp5b' },
];

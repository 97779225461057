import Actions from '../store/redux/actions/index.js';

import refreshPage from '../utils/refreshPage.js';

import { storageKeys } from '../constants/Enum.jsx';

function cacheCleaner(dispatch, navigate, auth) {
  var currentVersion = process.env.APP_VERSION;

  if (localStorage.getItem(storageKeys.appVersion) !== currentVersion && auth === '') {
    // localStorage.removeItem(storageKeys.timeZone);
    localStorage.setItem('appVersion', currentVersion);
    refreshPage();
  } else if (localStorage.getItem(storageKeys.appVersion) !== currentVersion) {
    dispatch(Actions.authActions.logoutAction(navigate, true));
  }
}

export default cacheCleaner;

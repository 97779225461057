import { useEffect } from 'react';

import config from '../config.json';

const useMarketingScript = () => {
  const gtmConfig = config.eCommerce.GTM;
  useEffect(() => {
    let gtmScript;
    let isScriptLoaded = false;

    const loadGTMScript = () => {
      gtmScript = document.createElement('script');
      gtmScript.innerHTML = `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://pk36kn3.meet2talk.online/pk36kn3.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','${gtmConfig.datalayerName}','${gtmConfig.containerId}');
      `;
      gtmScript.async = true;

      gtmScript.onload = () => {
        isScriptLoaded = true;
      };

      const head = document.head;
      head.prepend(gtmScript);
    };

    const loadGTMNoScript = () => {
      if (!isScriptLoaded) {
        const gtmNoScript = document.createElement('noscript');
        gtmNoScript.innerHTML = `
          <iframe src="https://pk36kn3.meet2talk.online/ns.html?id=${gtmConfig.containerId}"
          height="0" width="0" style="display:none;visibility:hidden" loading="lazy"></iframe>`;
        document.body.prepend(gtmNoScript);
      }
    };

    if (window.location.hostname === 'meet2talk.online') {
      loadGTMScript();
      loadGTMNoScript();
    }

    return () => {
      if (gtmScript && gtmScript.parentNode) {
        gtmScript.parentNode.removeChild(gtmScript);
      }
    };
  }, []);

  useEffect(() => {
    let pixel;
    let isScriptLoaded = false;

    const loadMetaScript = () => {
      const metaPixelScript = document.createElement('script');
      metaPixelScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${config.eCommerce.Meta.pixelId}');
        fbq.disablePushState = true;
      `;
      metaPixelScript.async = true;

      metaPixelScript.onload = () => {
        isScriptLoaded = true;
      };

      const head = document.head;
      head.appendChild(metaPixelScript);
      pixel = metaPixelScript;
    };

    const loadMetaNoScript = () => {
      if (!isScriptLoaded) {
        const metaPixelNoScript = document.createElement('noscript');
        metaPixelNoScript.innerHTML = `
          <img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=${config.eCommerce.Meta.pixelId}&ev=PageView&noscript=1" />
        `;
        document.body.appendChild(metaPixelNoScript);
      }
    };

    if (window.location.hostname === 'meet2talk.online') {
      setTimeout(() => {
        loadMetaScript();
        // loadMetaNoScript();
      }, 300);
    }

    return () => {
      if (pixel && pixel.parentNode) {
        pixel.parentNode.removeChild(pixel);
      }
    };
  }, []);
};

export default useMarketingScript;

import { Navigate } from 'react-router';

function checkGroupClaims(currentUserClaim, requiredClaim, content = undefined) {
  if (content !== undefined) {
    const hasClaim = currentUserClaim?.some((claim) => claim?.split('.')[0].includes(requiredClaim)) ? true : false;
    if (hasClaim) {
      return content;
    } else {
      return <Navigate to='/unauthorized' replace />;
    }
  } else if (content === undefined) {
    return currentUserClaim?.some((claim) => claim?.split('.')[0].includes(requiredClaim)) ? true : false;
  }
}
export default checkGroupClaims;

import { useMediaQuery } from 'react-responsive';

const useScreenSize = () => {
  return {
    isExtraSmallMobile: useMediaQuery({ maxWidth: 320 }),
    isSmallMobile: useMediaQuery({ minWidth: 321, maxWidth: 376 }),
    isLargeMobile: useMediaQuery({ minWidth: 376, maxWidth: 426 }),
    isSmallTablet: useMediaQuery({ minWidth: 992, maxWidth: 1023 }),
    isLargeTablet: useMediaQuery({ minWidth: 1280, maxWidth: 1439 }),

    isMobile: useMediaQuery({ maxWidth: 767 }),
    isTablet: useMediaQuery({ minWidth: 768, maxWidth: 1023 }),
    isDesktop: useMediaQuery({ minWidth: 1024, maxWidth: 1439 }),
    isLargeDesktop: useMediaQuery({ minWidth: 1920 }),
  };
};

export default useScreenSize;

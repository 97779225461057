import {
  faFacebook,
  faGoogle,
  faIdeal,
  faInstagram,
  faJediOrder,
  faLinkedin,
  faTiktok,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowDown,
  faArrowLeftLong,
  faArrowRightLong,
  faArrowUp,
  faArrowsDownToPeople,
  faBars,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarDays,
  faCalendarWeek,
  faCaretLeft,
  faCaretRight,
  faCartShopping,
  faChalkboardTeacher,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faCircleUser,
  faClock,
  faCogs,
  faCommentDots,
  faCommenting,
  faComputerMouse,
  faDesktop,
  faEllipsis,
  faEllipsisV,
  faExchange,
  faExclamationTriangle,
  faFaceGrinHearts,
  faFile,
  faFilePdf,
  faFilePen,
  faFileWord,
  faGaugeHigh,
  faGear,
  faGift,
  faGifts,
  faGlobe,
  faHeadphones,
  faHeartPulse,
  faInfoCircle,
  faListCheck,
  faListOl,
  faMinus,
  faNetworkWired,
  faPaperclip,
  faPause,
  faPenToSquare,
  faPeopleGroup,
  faPerson,
  faPhoneVolume,
  faPlaneDeparture,
  faPlay,
  faPlayCircle,
  faRankingStar,
  faRocket,
  faRotate,
  faSearch,
  faShareFromSquare,
  faShoppingCart,
  faSignOut,
  faSortDown,
  faSortUp,
  faSquareCheck,
  faTableList,
  faTags,
  faTrashCan,
  faTrophy,
  faUser,
  faUserEdit,
  faUserFriends,
  faUserTie,
  faUsers,
  faUsersGear,
  faWindowMaximize,
  faWindowMinimize,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';

export const BackGroundColors = {
  Primary: 'bg-primary',
  Secondary: 'bg-secondary',
  Success: 'bg-success',
  Danger: 'bg-danger',
  Warning: 'bg-warning',
  Info: 'bg-info',
  Light: 'bg-light',
  Dark: 'bg-dark',
  Deneme: 'bg-deneme',
  Custom: 'bg-custom',
  Successbg: 'bg-custom',
  Grey: 'bg-t-gri',
  Online: 'bg-custom',
  Cafe: 'bg-t-sarı',
  Kids: 'bg-t-turuncu',
  Company: 'bg-t-calendar',
  DashboardOnline: 'bg-d-online',
  DashboardCafe: 'bg-d-cafe',
  DashboardKids: 'bg-d-kids',
  DashboardCompany: 'bg-d-company',
  Lightcustom: 'bg-lightcustom',
  Purple: 'bg-t-mor',
  LandingGreen: 'bg-landing-green',
  GreyC: 'bg-c-gri',
  OrangeC: 'bg-c-orange',
  DarkYellow: 'bg-t-dark-yellow',
  DangerC: 'bg-custom-danger',
  Cream: 'bg-krem',
  Orange: 'bg-turuncu',
  SecondaryC: 'bg-secondary-light',
  Neutral: 'bg-neutral',
  ComparingWhite: 'bg-comparingWhite',
  BlueC: 'bg-t-mavi',
};
export const TextColors = {
  Primary: 'text-primary',
  Secondary: 'text-secondary',
  Success: 'text-success',
  Danger: 'text-danger',
  Warning: 'text-warning',
  Info: 'text-info',
  Light: 'text-light',
  Dark: 'text-dark',
  Custom: 'text-custom',
  Muted: 'text-muted',
  Online: 'text-custom',
  Cafe: 'text-d-cafe',
  Kids: 'text-t-turuncu',
  Company: 'text-t-calendar',
  Chat_Online: 'text-d-online',
  Chat_Cafe: 'text-d-cafe',
  Chat_Kids: 'text-d-kids',
  Chat_Company: 'text-d-company',
  Grey: 'text-t-gri',
  LightC: 'text-lightcustom',
  Purple: 'text-t-mor',
  YellowC: 'text-t-sarı',
  BlueC: 'text-t-mavi',
  LandingGreen: 'text-landing-green',
  OnlineGreen: 'text-online-green',
  GreyC: 'text-c-gri',
  OrangeC: 'text-c-orange',
  DarkYellow: 'text-t-dark-yellow',
  DangerC: 'text-custom-danger',
  Cream: 'text-krem',
  Orange: 'text-turuncu',
  Checked: 'text-checked',
  GiftLanding: 'text-landingGift',
};
export const FontAwesomeIcons = {
  faInfoCircle: faInfoCircle,
  faExclamationTriangle: faExclamationTriangle,
  faCheck: faCheck,
  faShoppingCart: faShoppingCart,
  faUserFriends: faUserFriends,
  faSearch: faSearch,
  faPlay: faPlay,
  faEllipsis: faEllipsis,
  faDesktop: faDesktop,
  faRotate: faRotate,
  faChevronLeft: faChevronLeft,
  faChevronRight: faChevronRight,
  faCheckCircle: faCheckCircle,
  faChevronUp: faChevronUp,
  faChevronDown: faChevronDown,
  faCommenting: faCommenting,
  faArrowRightLong: faArrowRightLong,
  faArrowLeftLong: faArrowLeftLong,
  faTrashCan: faTrashCan,
  faPlayCircle: faPlayCircle,
  faListCheck: faListCheck,
  faChartLine: faChartLine,
  faGlobe: faGlobe,
  faFilePdf: faFilePdf,
  faFileWord: faFileWord,
  faSortDown: faSortDown,
  faSortUp: faSortUp,
  faCartShopping: faCartShopping,
  faUser: faUser,
  faCogs: faCogs,
  faExchange: faExchange,
  faCircleUser: faCircleUser,
  faHeartPulse: faHeartPulse,
  faFilePen: faFilePen,
  faHeadphones: faHeadphones,
  faGear: faGear,
  faPenToSquare: faPenToSquare,
  faMinus: faMinus,
  faUserEdit: faUserEdit,
  faCalendarWeek: faCalendarWeek,
  faNetworkWired: faNetworkWired,
  faCalendarCheck: faCalendarCheck,
  faGifts: faGifts,
  faListOl: faListOl,
  faCalendarDays: faCalendarDays,
  faPeopleGroup: faPeopleGroup,
  faChalkboardTeacher: faChalkboardTeacher,
  faSignOut: faSignOut,
  faTags: faTags,
  faCircleExclamation: faCircleExclamation,
  faGaugeHigh: faGaugeHigh,
  faBars: faBars,
  faWindowMinimize: faWindowMinimize,
  faWindowMaximize: faWindowMaximize,
  faCalendar: faCalendar,
  faCircle: faCircle,
  faClock: faClock,
  faCalendarAlt: faCalendarAlt,
  faCalendarDay: faCalendarDay,
  faFaceGrinHearts: faFaceGrinHearts,
  faComputerMouse: faComputerMouse,
  faShareFromSquare: faShareFromSquare,
  faGift: faGift,
  faSquareCheck: faSquareCheck,
  faPaperclip: faPaperclip,
  faCircleCheck: faCircleCheck,
  faXmarkCircle: faXmarkCircle,
  faCaretLeft: faCaretLeft,
  faCaretRight: faCaretRight,
  faChevronCircleUp: faChevronCircleUp,
  faPause: faPause,
  faPerson: faPerson,
  faEllipsisV: faEllipsisV,
  faFile: faFile,
  faArrowUp: faArrowUp,
  faArrowDown: faArrowDown,
  faPhoneVolume: faPhoneVolume,
  faArrowsDownToPeople: faArrowsDownToPeople,
  faUserTie: faUserTie,
  faCommentDots: faCommentDots,
  faTrophy: faTrophy,
  faRankingStar: faRankingStar,
  faUsers: faUsers,
  faUsersGear: faUsersGear,
  faTableList: faTableList,
  faPlaneDeparture: faPlaneDeparture,
  faRocket: faRocket,
};
export const FontAwesomeBrandIcons = {
  faFacebook: faFacebook,
  faGoogle: faGoogle,
  faInstagram: faInstagram,
  faLinkedin: faLinkedin,
  faTiktok: faTiktok,
  faXTwitter: faXTwitter,
  faYoutube: faYoutube,
  faIdeal: faIdeal,
  faJediOrder: faJediOrder,
};
export const ButtonSize = {
  Small: 'btn-sm',
  Large: 'btn-lg',
  ExtraSmall: 'btn-xs',
};
export const ButtonWidth = {
  S: 'w-25',
  M: 'w-50',
  L: 'w-75',
  XL: 'w-100',
};
export const InputType = {
  Button: 'button',
  Checkbox: 'checkbox',
  Color: 'color',
  Date: 'date',
  DateTimeLocal: 'datetime-local',
  Email: 'email',
  File: 'file',
  Hidden: 'hidden',
  Image: 'image',
  Month: 'month',
  Number: 'number',
  Password: 'password',
  Radio: 'radio',
  Range: 'range',
  Reset: 'reset',
  Search: 'search',
  Submit: 'submit',
  Switch: 'switch',
  Tel: 'tel',
  Text: 'text',
  Time: 'time',
  Url: 'url',
  Week: 'week',
  TextArea: 'textarea',
};
export const RegexType = {
  Name: 'name',
};
export const InputSize = {
  Small: 'form-control form-control-sm w-25 mt-2',
  Medium: 'form-control w-100 mt-2',
  Large: 'form-control w-75 form-control-lg h-50 mt-2',
};
export const LabelTextType = {
  Normal: 'fw-normal',
  Light: 'fw-light',
  Bold: 'fw-bold',
  Bolder: 'fw-bolder',
  Italic: 'fst-italic',
};
export const LabelFontSize = {
  fs1: ' fs-1 ',
  fs2: ' fs-2 ',
  fs3: ' fs-3 ',
  fs4: ' fs-4 ',
  fs5: ' fs-5 ',
  fs6: ' fs-6 ',
};
export const AlertType = {
  Success: 'success',
  Info: 'info',
  Warning: 'warning',
  Error: 'error',
};
export const AlertColor = {
  Success: ' btn btn-success',
  Info: ' btn btn-info',
  Warning: ' btn btn-warning',
  Error: ' btn btn-danger',
};
export const TextAreaWidth = {
  WTwentyFive: ' w-25',
  WFifty: ' w-50',
  WSeventyFive: ' w-75',
  WHundred: ' w-100',
};
export const TextRounded = {
  Rounded: ' rounded',
};
export const ToastContainerType = {
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
  XLarge: 'xlarge',
};

import { useEffect, useState } from 'react';

const useIntersectionObserver = (ref, options = { threshold: 0.1 }) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current); // Daha güvenli yöntem
      }
    };
  }, [ref, options]);

  return isIntersecting;
};

export default useIntersectionObserver;

import { initReactI18next } from 'react-i18next';

import { registerLocale } from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import trLocale from 'i18n-iso-countries/langs/tr.json';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';

const fallbackLng = ['en-GB'];
const availableLanguages = ['en-GB', 'tr-TR'];
const isProduction = process.env.NODE_ENV === 'production';

// const options = {
//   order: ['navigator', 'htmlTag', 'path', 'subdomain'],
//   lookupQuerystring: 'lng',
//   lookupCookie: 'i18next',
//   lookupLocalStorage: 'i18nextLng',
//   lookupFromPathIndex: 0,
//   lookupFromSubdomainIndex: 0,
//   caches: ['localStorage', 'cookie'],
//   excludeCacheFor: ['cimode'],
//   cookieMinutes: 10,
//   cookieDomain: 'myDomain',
//   htmlTag: document.documentElement,
//   checkWhitelist: true,
// };
registerLocale(enLocale);
registerLocale(trLocale);

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng,
    debug: false,
    whitelist: availableLanguages,
    load: 'currentOnly',
    //detection: options,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: (lng, ns) => {
        const hash = lng.some((language) => language.includes('en'))
          ? process.env.EN_TRANSLATION_HASH
          : process.env.TR_TRANSLATION_HASH;
        return isProduction ? `/locales/${lng}/${ns}.${hash}.json.gz` : `/locales/${lng}/${ns}.${hash}.json`;
      },
    },
  });

export default i18n;

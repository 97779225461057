export const storageKeys = {
  languge: 'i18nextLng',
  token: 'token',
  keepLoggedIn: 'isLoggedIn',
  landingRule: 'landingRule', // for saving landing package datas while login, end successfully operation.
  levelGroup: 'levelGroup',
  currentMenu: 'currentMenu', // keeps user view choice on calendar.
  resetMentors: 'reset', // reset browser history on reloading or changing of mentors.
  userArea: 'userArea', // area for cookie
  firstRegister: 'firstRegister',
  firstRegisterLanding: 'firstRegisterLanding',
  speakerId: 'sId', // setting speaker id for mentor side view.
  UIVersion: 'UIVersion',
  hardReset: 'hardReset',
  path: 'path',
  utmData: 'utmData',
  utmDataInsert: 'followUp',
  tiktok: 'tiktok',
  invitation: 'invitation',
  userType: 'userType',
  timeZone: 'timeZone',
  appVersion: 'appVersion',
  datalayerBuy: 'datalayerBuy',
  datalayerDemo: 'datalayerDemo',
  gtmFirstUniqueId: 'gtmFirstUniqueId',
  gtmLastUniqueId: 'gtmLastUniqueId',
  landingItemId: 'landingItemId',
};
export const storageValues = {
  landingRule: 'active',
  keepLoggedIn: 'true',
  reset: 'true',
  currentMenu: { normal: 'normal', schedule: 'schedule' },
  firstRegister: true,
  firstRegisterLanding: true,
  hardReset: true,
  tiktok: 'true',
  invitation: 'true',
};
export const reponseStatus = {
  default: '',
  ongoing: null,
  success: true,
  fail: false,
};
export const responseErrorStatus = {
  RetryTimeOut: 'RetryTimeOut.system',
  RequestTimedOut: 'RequestTimedOut.system',
  BadRequest: 'BadRequest.system',
};
export const formActions = {
  register: { key: 'register', value: 0 },
  letUsCall: { key: 'let us call', value: 1 },
  freeTrial: { key: 'free trial', value: 2 },
  package: { key: 'package buy', value: 3 },
  campaign: { key: 'campaign form', value: 4 },
};

export const LanguagesForSite = {
  eng: 'en-GB',
  tr: 'tr-TR',
};
export const Languages = {
  eng: 'English',
  tr: 'Türkçe',
};
export const UserAreasSring = {
  Online: 'Online',
  Cafe: 'Cafe',
  Kids: 'Kids',
  Company: 'Company',
  None: 'None',
};
export const Flags = {
  greatBritain: 'gb',
  turkey: 'tr',
};
export const People = {
  Online: [
    { key: 1, name: 'People', value: 1 },
    { key: 3, name: 'People', value: 3 },
    { key: 6, name: 'People', value: 6 },
  ],
  Cafe: [
    { key: 6, name: 'People', value: 6 },
    { key: 8, name: 'People', value: 8 },
  ],
  Company: [
    { key: 1, name: 'People', value: 1 },
    { key: 3, name: 'People', value: 3 },
    { key: 6, name: 'People', value: 6 },
    { key: 8, name: 'People', value: 8 },
  ],
};
export const ProductType = [
  { key: 'Private', name: 'ProductType', value: 'Private' },
  { key: 'Group', name: 'ProductType', value: 'Group' },
];
export const serviceOptions = [
  { key: 'UserArea.0', name: 'UserArea', value: 0, url: '' },
  { key: 'UserArea.1', name: 'UserArea', value: 1, url: '/cafe' },
  { key: 'UserArea.2', name: 'UserArea', value: 2, url: '/kids' },
];
export const defaultLevelGroup = {
  adult: 'Quintet',
  kids: 'KidsLangLevel',
};
export const TimesPerWeek = [
  { perWeek: 1 },
  { perWeek: 2 },
  { perWeek: 3 },
  { perWeek: 4 },
  { perWeek: 5 },
  { perWeek: 6 },
  { perWeek: 7 },
];
export const MonthOptions = [
  { month: 1, key: 1, search: 1 },
  { month: 2, key: 3, search: 3 },
  { month: 3, key: 6, search: 6 },
  { month: 4, key: 12, search: 12 },
];
export const SessionCapacity = [
  { capacity: 1, search: 1 },
  { capacity: 3, search: 3 },
  { capacity: 6, search: 6 },
  { capacity: 8, search: 8 },
];
export const BankNames = {
  banks: {
    'AKBANK T.A.S.': 'https://www.paytr.com/img/odeme_sayfasi/axess_taksit.png',
    'T.C. ZIRAAT BANKASI, A.S.': 'https://www.paytr.com/img/odeme_sayfasi/combo_taksit.png',
    'TURKIYE GARANTI BANKASI A. S.': 'https://www.paytr.com/img/odeme_sayfasi/bonus_taksit.png',
    'FINANSBANK, A.S.': 'https://www.paytr.com/img/odeme_sayfasi/cardfinans_taksit.png?v01',
    'TURKIYE IS BANKASI, A.S.': 'https://www.paytr.com/img/odeme_sayfasi/maximum_taksit.png',
    'TURKIYE HALK BANKASI, A.S.': 'https://www.paytr.com/img/odeme_sayfasi/paraf_taksit.png',
    'YAPI KREDI': 'https://www.paytr.com/img/odeme_sayfasi/world_taksit.png',
    'KUVEYT TURK KATILIM BANKASI, A.S.': 'https://www.paytr.com/img/odeme_sayfasi/saglam_taksit.png',
    HSBC: 'https://www.paytr.com/img/odeme_sayfasi/advantage_taksit.png',
  },
};

export const AllLanguages = [
  {
    key: 'Language.0',
    value: 'English',
    name: 'Language',
  },
  {
    key: 'Language.1',
    value: 'Türkçe',
    name: 'Language',
  },
];

export const Language = [
  {
    key: 'Language.0',
    value: '0',
    name: 'Language',
  },
  {
    key: 'Language.1',
    value: '1',
    name: 'Language',
  },
];
export const Gender = [
  {
    key: 'Gender.0',
    value: '0',
    name: 'Gender',
  },
  {
    key: 'Gender.1',
    value: '1',
    name: 'Gender',
  },
];
export const LearningPurpose = [
  {
    key: 'LearningPurpose.0',
    value: '0',
    name: 'LearningPurpose',
    search: 'Travel',
  },
  {
    key: 'LearningPurpose.1',
    value: '1',
    name: 'LearningPurpose',
    search: 'Abroad',
  },
  {
    key: 'LearningPurpose.2',
    value: '2',
    name: 'LearningPurpose',
    search: 'Self Improvement',
  },
  {
    key: 'LearningPurpose.3',
    value: '3',
    name: 'LearningPurpose',
    search: 'Exam',
  },
  {
    key: 'LearningPurpose.4',
    value: '4',
    name: 'LearningPurpose',
    search: 'Job Interview',
  },
  {
    key: 'LearningPurpose.5',
    value: '5',
    name: 'LearningPurpose',
    search: 'Specialize',
  },
];
export const LanguageLevelGroup = {
  Binary: [
    {
      key: 'Binary.0',
      value: 'Binary.0',
      name: 'LanguageLevels',
    },
    {
      key: 'Binary.1',
      value: 'Binary.1',
      name: 'LanguageLevels',
    },
  ],
  Triple: [
    {
      key: 'Triple.0',
      value: 'Triple.0',
      name: 'LanguageLevels',
    },
    {
      key: 'Triple.1',
      value: 'Triple.1',
      name: 'LanguageLevels',
    },
    {
      key: 'Triple.2',
      value: 'Triple.2',
      name: 'LanguageLevels',
    },
  ],
  Quartet: [
    {
      key: 'Quartet.0',
      value: 'Quartet.0',
      name: 'LanguageLevels',
    },
    {
      key: 'Quartet.1',
      value: 'Quartet.1',
      name: 'LanguageLevels',
    },
    {
      key: 'Quartet.2',
      value: 'Quartet.2',
      name: 'LanguageLevels',
    },
    {
      key: 'Quartet.3',
      value: 'Quartet.3',
      name: 'LanguageLevels',
    },
  ],
  Quintet: [
    {
      key: 'Quintet.0',
      value: 'Quintet.0',
      name: 'LanguageLevels',
      search: 'First Step',
    },
    {
      key: 'Quintet.1',
      value: 'Quintet.1',
      name: 'LanguageLevels',
      search: 'Early Stage',
    },
    {
      key: 'Quintet.2',
      value: 'Quintet.2',
      name: 'LanguageLevels',
      search: 'Communicator',
    },
    {
      key: 'Quintet.3',
      value: 'Quintet.3',
      name: 'LanguageLevels',
      search: 'Talker',
    },
    {
      key: 'Quintet.4',
      value: 'Quintet.4',
      name: 'LanguageLevels',
      search: 'Presenter',
    },
  ],
  Sestet: [
    {
      key: 'Sestet.0',
      value: 'Sestet.0',
      name: 'LanguageLevels',
    },
    {
      key: 'Sestet.1',
      value: 'Sestet.1',
      name: 'LanguageLevels',
    },
    {
      key: 'Sestet.2',
      value: 'Sestet.2',
      name: 'LanguageLevels',
    },
    {
      key: 'Sestet.3',
      value: 'Sestet.3',
      name: 'LanguageLevels',
    },
    {
      key: 'Sestet.4',
      value: 'Sestet.4',
      name: 'LanguageLevels',
    },
    {
      key: 'Sestet.5',
      value: 'Sestet.5',
      name: 'LanguageLevels',
    },
  ],
  KidsLangLevel: [
    {
      key: 'KidsLangLevel.0',
      value: 'KidsLangLevel.0',
      name: 'LanguageLevels',
      search: 'Baby Talk',
    },
    {
      key: 'KidsLangLevel.1',
      value: 'KidsLangLevel.1',
      name: 'LanguageLevels',
      search: 'Early Talk',
    },
    {
      key: 'KidsLangLevel.2',
      value: 'KidsLangLevel.2',
      name: 'LanguageLevels',
      search: 'Talky Talk',
    },
  ],
};
export const KidsLangLevel = [
  {
    key: 'KidsLangLevel.0',
    value: '0',
    name: 'KidsLangLevel',
    search: 'Baby Talk',
  },
  {
    key: 'KidsLangLevel.1',
    value: '1',
    name: 'KidsLangLevel',
    search: 'Early Talk',
  },
  {
    key: 'KidsLangLevel.2',
    value: '2',
    name: 'KidsLangLevel',
    search: 'Talky Talk',
  },
];
export const TypeOfService = [
  {
    key: 'TypeOfService.1',
    value: '1',
    name: 'TypeOfService',
  },
  {
    key: 'TypeOfService.0',
    value: '0',
    name: 'TypeOfService',
  },
];
export const UserArea = [
  {
    key: 'UserArea.0',
    value: '0',
    name: 'UserArea',
    path: '/online',
  },
  {
    key: 'UserArea.1',
    value: '1',
    name: 'UserArea',
    path: '/cafe',
  },
  {
    key: 'UserArea.2',
    value: '2',
    name: 'UserArea',
    path: '/kids',
  },
  {
    key: 'UserArea.3',
    value: '3',
    name: 'UserArea',
    path: '/company',
  },
];
export const Native = [
  {
    key: 'Native.0',
    value: '0',
    name: 'Native',
  },
  {
    key: 'Native.1',
    value: '1',
    name: 'Native',
  },
];
export const NotificationType = [
  {
    key: 'NotificationType.0',
    value: '0',
    name: 'NotificationType',
  },
  {
    key: 'NotificationType.1',
    value: '1',
    name: 'NotificationType',
  },
  {
    key: 'NotificationType.2',
    value: '2',
    name: 'NotificationType',
  },
  {
    key: 'NotificationType.3',
    value: '3',
    name: 'NotificationType',
  },
];
export const NotificationCategory = [
  {
    key: 'NotificationCategory.0',
    value: '0',
    name: 'NotificationCategory',
  },
  {
    key: 'NotificationCategory.1',
    value: '1',
    name: 'NotificationCategory',
  },
  {
    key: 'NotificationCategory.2',
    value: '2',
    name: 'NotificationCategory',
  },
  {
    key: 'NotificationCategory.3',
    value: '3',
    name: 'NotificationCategory',
  },
];

export const LanguageCategory = [
  {
    key: 'LanguageCategory.0',
    value: '0',
    name: 'NotificationCategory',
  },
  {
    key: 'LanguageCategory.1',
    value: '1',
    name: 'NotificationCategory',
  },
];

export const DayOfWeeks = [
  {
    key: 'DayOfWeeks.0',
    value: 'Sunday',
    name: 'DayOfWeek',
  },
  {
    key: 'DayOfWeeks.1',
    value: 'Monday',
    name: 'DayOfWeek',
  },
  {
    key: 'DayOfWeeks.2',
    value: 'Tuesday',
    name: 'DayOfWeek',
  },
  {
    key: 'DayOfWeeks.3',
    value: 'Wednesday',
    name: 'DayOfWeek',
  },
  {
    key: 'DayOfWeeks.4',
    value: 'Thursday',
    name: 'DayOfWeek',
  },
  {
    key: 'DayOfWeeks.5',
    value: 'Friday',
    name: 'DayOfWeek',
  },
  {
    key: 'DayOfWeeks.6',
    value: 'Saturday',
    name: 'DayOfWeek',
  },
];
export const OrderStatus = [
  {
    key: 'OrderStatus.0',
    value: '0',
    name: 'OrderStatus',
  },
  {
    key: 'OrderStatus.1',
    value: '1',
    name: 'OrderStatus',
  },
  {
    key: 'OrderStatus.2',
    value: '2',
    name: 'OrderStatus',
  },
  {
    key: 'OrderStatus.3',
    value: '3',
    name: 'OrderStatus',
  },
  {
    key: 'OrderStatus.4',
    value: '4',
    name: 'OrderStatus',
  },
];
export const MeetingStatus = [
  {
    key: 'MeetingStatus.0',
    value: '0',
    name: 'MeetingStatus',
  },
  {
    key: 'MeetingStatus.1',
    value: '1',
    name: 'MeetingStatus',
  },
  {
    key: 'MeetingStatus.2',
    value: '2',
    name: 'MeetingStatus',
  },
  {
    key: 'MeetingStatus.3',
    value: '3',
    name: 'MeetingStatus',
  },
  {
    key: 'MeetingStatus.4',
    value: '4',
    name: 'MeetingStatus',
  },
  {
    key: 'MeetingStatus.5',
    value: '5',
    name: 'MeetingStatus',
  },
  {
    key: 'MeetingStatus.6',
    value: '6',
    name: 'MeetingStatus',
  },
];
export const PaymentStatus = [
  {
    key: 'PaymentStatus.0',
    value: '0',
    name: 'PaymentStatus',
  },
  {
    key: 'PaymentStatus.1',
    value: '1',
    name: 'PaymentStatus',
  },
  {
    key: 'PaymentStatus.2',
    value: '2',
    name: 'PaymentStatus',
  },
  {
    key: 'PaymentStatus.3',
    value: '3',
    name: 'PaymentStatus',
  },
];
export const PaymentType = [
  {
    key: 'PaymentType.0',
    value: '0',
    name: 'PaymentType',
  },
  {
    key: 'PaymentType.1',
    value: '1',
    name: 'PaymentType',
  },
  {
    key: 'PaymentType.2',
    value: '2',
    name: 'PaymentType',
  },
  {
    key: 'PaymentType.3',
    value: '3',
    name: 'PaymentType',
  },
  {
    key: 'PaymentType.4',
    value: '4',
    name: 'PaymentType',
  },
  {
    key: 'PaymentType.5',
    value: '5',
    name: 'PaymentType',
  },
];
export const CurrencyUnit = [
  {
    key: 'USD',
    value: 840,
    name: 'USD',
  },
  {
    key: 'AZN',
    value: 944,
    name: 'AZN',
  },
  {
    key: 'TL',
    value: 949,
    name: 'TL',
  },
  {
    key: 'EUR',
    value: 978,
    name: 'EUR',
  },
];
export const MeetingAttendeeStatus = [
  {
    key: 'MeetingAttendeeStatus.0',
    value: '0',
    name: 'MeetingAttendeeStatus',
  },
  {
    key: 'MeetingAttendeeStatus.1',
    value: '1',
    name: 'MeetingAttendeeStatus',
  },
  {
    key: 'MeetingAttendeeStatus.2',
    value: '2',
    name: 'MeetingAttendeeStatus',
  },
  {
    key: 'MeetingAttendeeStatus.3',
    value: '3',
    name: 'MeetingAttendeeStatus',
  },
  {
    key: 'MeetingAttendeeStatus.4',
    value: '4',
    name: 'MeetingAttendeeStatus',
  },
  {
    key: 'MeetingAttendeeStatus.5',
    value: '5',
    name: 'MeetingAttendeeStatus',
  },
  {
    key: 'MeetingAttendeeStatus.6',
    value: '6',
    name: 'MeetingAttendeeStatus',
  },
];
export const UserStatus = [
  {
    key: 'UserStatus.0',
    value: '0',
    name: 'UserStatus',
  },
  {
    key: 'UserStatus.1',
    value: '1',
    name: 'UserStatus',
  },
];
export const ActivityStatus = [
  {
    key: 'ActivityStatus.0',
    value: '0',
    name: 'Activity',
  },
  {
    key: 'ActivityStatus.1',
    value: '1',
    name: 'UserStaActivitytus',
  },
];
export const OrderItemStatus = [
  {
    key: 'OrderItemStatus.0',
    value: '0',
    name: 'OrderItemStatus',
  },
  {
    key: 'OrderItemStatus.1',
    value: '1',
    name: 'OrderItemStatus',
  },
  {
    key: 'OrderItemStatus.2',
    value: '2',
    name: 'OrderItemStatus',
  },
];
export const ConfirmationModalActions = {
  removeAction: {
    key: 1,
    value: 2,
    name: 'table.remove',
    text: 'delete',
  },
  setPayedAction: {
    key: 2,
    value: 3,
    name: 'table.setPayed',
    text: 'setPayed',
  },
  recompenseAction: {
    key: 3,
    value: 5,
    name: 'table.recompense',
    text: 'recompense',
  },
  interviewedAction: {
    key: 4,
    value: 10,
    name: 'table.interviewed',
    text: 'interviewed',
  },
  changePaymentStatusAction: {
    key: 5,
    value: 12,
    name: 'table.changePaymentStatus',
    text: 'changePaymentStatus',
  },
  unrecompenseAction: {
    key: 6,
    value: 14,
    name: 'table.unrecompense',
    text: 'unRecompense',
  },
  recompenseMeetingAction: {
    key: 7,
    value: 100,
    name: 'dropdown.recompenseMeeting',
    text: 'recompenseMeeting',
  },
  cancelMeetingAction: {
    key: 8,
    value: 101,
    name: 'dropdown.cancelMeeting',
    text: 'cancelMeeting',
  },
  phoneConfirmationAction: {
    key: 9,
    value: 16,
    name: 'table.phoneConfirmation',
    text: 'phoneConfirmation',
  },
  setDefaultPasswordAction: {
    key: 10,
    value: 19,
    name: 'table.setDefaultPassword',
    text: 'setDefaultPassword',
  },
  freezeMeetingAction: {
    key: 11,
    value: 102,
    name: 'dropdown.freezeMeeting',
    text: 'freezeMeeting',
  },
  cancelPenaltyAction: {
    key: 12,
    value: 22,
    name: 'table.cancelPenalty',
    text: 'cancelPenalty',
  },
  changeActivityStatusAction: {
    key: 13,
    value: 25,
    name: 'table.changeActivityStatus',
    text: 'changeActivityStatus',
  },
};
export const BasicConfirmModalActions = {
  logOut: {
    title: 'logOutTitle',
    text: 'logOutText',
    firstButton: { className: 'btn btn-outline-danger', text: 'button.yes' },
    secondButton: { className: 'btn btn-outline-success', text: 'button.no' },
  },
};
export const ModalTitles = {
  phoneConfirmation: 'calendar.phone_confirmation',
  attendeeEvaluation: 'attendee.attendeeEvaluation',
  changeSpeaker: 'table.changeSpeaker',
  coverMeeting: 'table.coverMeeting',
  changeEffortOwnership: 'table.changeEffortOwnership',
  changeLevel: 'table.changeLevel',
};
export const ActionFilterRules = {
  attendee: 1,
  order: 2,
  meetingDetails: 3,
  company: 4,
  register: 5,
  cover: 6,
  effort: 7,
  meetingList: 8,
  career: 9,
  speaker: 10,
  manager: 11,
};
export const Entities = {
  meetingSchema: {
    claim: 'meetingSchema',
    text: 'Meeting Schema',
    exportName: 'meetingSchema-list',
  },
  speaker: {
    claim: 'speaker',
    text: 'Speaker',
    exportName: {
      speaker: 'speaker-list',
      effortDetials: 'speaker-effort-details',
      effortByBranches: 'paymentsby-branches',
      effortByArea: 'paymentsby-area',
      effortBySpeaker: 'paymentsby-speakers',
    },
  },
  speakerPayment: {
    claim: 'speakerPayment',
    text: 'Speaker Payment',
    exportName: {
      effortDetials: 'speaker-effort-details',
    },
  },
  speakerEffort: {
    claim: 'speakerEffort',
    text: 'Speaker Effort',
    exportName: {
      effortDetials: 'speaker-effort-details',
      effortByBranches: 'paymentsby-branches',
      effortByArea: 'paymentsby-area',
      effortBySpeaker: 'paymentsby-speakers',
    },
  },
  attendee: {
    claim: 'attendee',
    text: 'Attendee',
    exportName: 'attendee-list',
  },
  manager: { claim: 'manager', text: 'Manager', exportName: 'manager-list' },
  meeting: { claim: 'meeting', text: 'Meeting', exportName: 'meeting-list' },
  meetingAttendee: {
    claim: 'meetingAttendee',
    text: 'Meeting Attendee',
    exportName: 'meetingAttendee-list',
  },
  order: {
    claim: 'order',
    text: 'Order',
    exportName: {
      order: 'order-list',
      orderItem: 'order-item-list',
      payments: 'payment-list',
      eft: 'eft-list',
      todaysTrial: 'created-today-trials',
      upcomingTrial: 'todays-trials',
    },
  },
  orderItem: {
    claim: 'orderItem',
    text: 'Order Item',
    exportName: 'orderItem-list',
  },
  feedBack: {
    claim: 'feedback',
    text: 'Feedback',
    exportName: 'feedback-list',
  },
  career: { claim: 'career', text: 'Career', exportName: 'career-list' },
  mailSubscription: {
    claim: 'mailSubscription',
    text: 'Mail Subscription',
    exportName: 'call-them',
  },
  lead: {
    claim: 'lead',
    text: 'Lead',
    exportName: 'lead-list',
  },
  banner: {
    claim: 'banner',
    text: 'Banner',
    exportName: 'banner-list',
  },
  branchFreeze: {
    claim: 'branchFreeze',
    text: 'Branch Freeze',
    exportName: 'branchFreeze-list',
  },
  couponCodes: {
    claim: 'couponCodes',
    text: 'Coupon Codes',
    exportName: 'couponCodes-list',
  },
  ageInterval: {
    claim: 'ageInterval',
    text: 'Age Interval',
    exportName: 'ageInterval-list',
  },
  location: {
    claim: 'location',
    text: 'Location',
    exportName: 'location-list',
  },
  sessionCategory: {
    claim: 'sessionCategory',
    text: 'Session Category',
    exportName: 'sessionCategory-list',
  },
  sessionPackage: {
    claim: 'sessionPackage',
    text: 'Session Package',
    exportName: 'sessionPackage-list',
  },
  timesPerMonth: {
    claim: 'timesPerMonth',
    text: 'Times PerMonth',
    exportName: 'timesPerMonth-list',
  },
  timesPerWeek: {
    claim: 'timesPerWeek',
    text: 'Times PerWeek',
    exportName: 'timesPerWeek-list',
  },
  zone: { claim: 'zone', text: 'Zone', exportName: 'zone-list' },
  company: {
    claim: 'company',
    text: 'Company',
    exportName: {
      company: 'company-list',
    },
  },
  campaign: {
    claim: 'campaign',
    text: 'Campaign',
    exportName: 'campaign-list',
  },
  reporting: {
    claim: 'manager',
    text: 'Reporting',
    exportName: 'filtered-list',
  },
  companyForm: {
    claim: 'companyForm',
    text: 'Company Form',
    exportName: 'company-form-list',
  },
};
export const RemainingCalculationStatus = [
  {
    key: 'RemainingCalculationStatus.0',
    value: true,
    name: 'RemainingCalculationStatus',
  },
  {
    key: 'RemainingCalculationStatus.1',
    value: false,
    name: 'RemainingCalculationStatus',
  },
  {
    key: 'RemainingCalculationStatus.2',
    value: null,
    name: 'RemainingCalculationStatus',
  },
];
export const LeadStatus = [
  {
    key: 'LeadStatus.0',
    value: '0',
    name: 'LeadStatus',
  },
  {
    key: 'LeadStatus.1',
    value: '1',
    name: 'LeadStatus',
  },
];
